<template>
	<div id="livePage">
		<div class="info" v-if="meettingLink">
			<i class="el-icon-orange"></i>
			<p>请回到直播页面进行会诊。</p>
			<p>
				如无法直接跳转直播页面请
				<el-link type="primary" @click="toLivePage()">点击跳转直播页面</el-link>
			</p>
		</div>
	</div>
</template>

<script>
	// import {
	// 	joinMeeting
	// } from '@/request/api.js';
	export default {
		name: 'livePage',
		components: {},
		data() {
			return {
				meetingId: '',
				meettingLink: ''
			}
		},
		created() {
			this.meetingId = this.$route.params.id;
			// this.joinMeetingFun();
		},
		mounted() {},
		methods: {
			toLivePage() {
				this.joinMeetingFun();
			},

			joinMeetingFun() {
				let data = {
					meetingId: this.meetingId
				}
				joinMeeting(data).then(res => {
					if (res.code == 200) {
						this.meettingLink = res.data.link;
						window.open(this.meettingLink);
					} else {
						this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							showCancelButton: false,
							type: 'info'
						}).then(() => {
							this.$router.go(-1);
						}).catch(() => {
							this.$router.go(-1);
						});

					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#livePage {
		padding: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		.info {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;

			.el-icon-orange {
				font-size: 100px;
				margin-bottom: 20px;
			}

			p {
				display: flex;
				font-size: 16px;
				text-align: center;
				margin-bottom: 10px;
				align-items: center;

				.el-link {
					font-size: 16px;
				}
			}
		}
	}
</style>